<template>
  <div class="home">
    <div class="container">
      <v-header :menu="true" />
    </div>
    <div class="section dt12 db15 mt7 mb10">
      <div class="container">
        <div class="row">
          <div class="d-8 d-center">
            <h1>Организуем ваш корпоративный удостоверя&shy;ющий центр</h1>
            <p class="leading dv7 mv6">
              Легко и&nbsp;быстро выдавайте электронные подписи любому
              количеству сотрудников прямо на&nbsp;рабочем месте.
            </p>
            <a href="#" class="button m-center" @click.prevent="showModal">
              Отправить запрос
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/verification-center/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/enter-otp/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6" v-onscroll>
            <h2>Зачем нужен корпоративный удостоверяющий центр</h2>
            <p class="md">
              Компаниям со&nbsp;штатом в&nbsp;несколько тысяч сотрудников
              выпускать&nbsp;ЭП через посредников сложно и&nbsp;затратно.
              Выгоднее организовать свой удостоверяющий центр, выдавать подписи
              сотрудникам на&nbsp;рабочем месте и&nbsp;не&nbsp;платить
              за&nbsp;эти услуги.
            </p>
            <p class="md">
              Для подписания кадровых документов достаточно неквалифицированной
              ЭП. Мы&nbsp;поможем организовать свой&nbsp;УЦ, и&nbsp;вам
              не&nbsp;придётся вызывать специалистов в&nbsp;офис или
              ездить в&nbsp;сторонние&nbsp;УЦ.
            </p>
          </div>
          <div class="d-5 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/verification-center/pic-01.svg"
              data-copyright-source="https://storyset.com/illustration/enter-otp/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section section-dark v15 mt7 mb7" v-onscroll>
      <div class="container">
        <div class="row">
          <div class="d-12 dt10 db7 mt7 d-center">
            <h2>Этапы работ</h2>
          </div>
        </div>
        <div class="row">
          <div class="d-3 db11 mt6" v-onscroll>
            <div class="card">
              <p class="card-title">1</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Консалтинг</p>
              <p class="card-text">
                Возьмём на&nbsp;себя полное сопровождение проекта от&nbsp;бизнес-анализа до&nbsp;реализации
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll>
            <div class="card">
              <p class="card-title">2</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Оборудование</p>
              <p class="card-text">
                Поможем купить специализированное&nbsp;ПО, разместим серверное
                оборудование
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 250 }">
            <div class="card">
              <p class="card-title">3</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Документация</p>
              <p class="card-text">
                Разработаем инструкции и&nbsp;регламенты, поможем получить
                лицензии
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6 mb7" v-onscroll="{ delay: 500 }">
            <div class="card">
              <p class="card-title">4</p>
              <p class="card-subtitle dt1 db3 mt1 mb2">Релиз</p>
              <p class="card-text">
                Протестируем выдачу&nbsp;ЭП на&nbsp;небольшом количестве
                сотрудников, после чего масштабируем решение
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb10">
      <div class="container">
        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/verification-center/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/devices/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Удобно подписывать</h2>
            <p>
              <strong>Доступ из&nbsp;любого места.</strong> Неважно, где
              находится сотрудник&nbsp;&mdash; в&nbsp;командировке,
              на&nbsp;удалёнке или в&nbsp;отпуске. Он&nbsp;всегда сможет
              подписать кадровый документ, не&nbsp;тормозя процесс.
            </p>
            <p>
              <strong>Через мобильное приложение</strong> сотрудники смогут
              мгновенно получать уведомления и&nbsp;заверять документы подписью
              в&nbsp;пару кликов.
            </p>
            <p>
              <strong>Через корпоративный портал</strong> можно не&nbsp;только
              подписывать документы, но&nbsp;и&nbsp;настраивать маршруты
              согласования документов между сотрудниками разных отделов
              и&nbsp;ускорять процесс.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/verification-center/pic-02.svg"
              data-copyright-source="https://storyset.com/illustration/devices/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>

        <div class="row middle dv15 mv10">
          <div class="d-5 mb4" v-onscroll>
            <img src="@/assets/pages/verification-center/pic-03.svg"
              data-copyright-source="https://storyset.com/illustration/fill-out/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-6 d-offset-1" v-onscroll>
            <h2>Легко управлять</h2>
            <p>
              <strong>Неквалифицированная подпись.</strong> Её&nbsp;достаточно,
              чтобы подписывать внутренние документы&nbsp;&mdash; заявления,
              приказы, служебные записки, инструкции.
            </p>
            <p>
              <strong>Выдача в&nbsp;пару кликов.</strong> Несколько минут нужно
              на&nbsp;то, чтобы выдать подпись сотруднику. Мы&nbsp;обучим
              кадрового специалиста и&nbsp;дадим понятные инструкции.
            </p>
            <p>
              <strong>Всё под контролем.</strong> WorkCat напомнит, когда
              закончится срок действия подписи, покажет список владельцев,
              статус подписи и&nbsp;статистику использования.
            </p>
          </div>
        </div>

        <div class="row middle">
          <div class="d-6 d-offset-1 d-hide mb4">
            <img src="@/assets/pages/verification-center/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/secure-login/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
          <div class="d-5" v-onscroll>
            <h2>Безопасно использовать</h2>
            <p>
              <strong>Нельзя подделать.</strong> Электронные подписи надёжнее
              ручного подписания бумаг, их&nbsp;можно использовать в&nbsp;суде
              в&nbsp;качестве доказательств.
            </p>
            <p>
              <strong>Полная безопасность.</strong> WorkCat использует HSM
              (Hardware security module), который надёжно хранит ключи
              и&nbsp;поддерживает ролевую модель пользователей.
            </p>
            <p>
              <strong>Контроль.</strong> Использовать ключ пользователи могут
              только по&nbsp;аутентифицированному запросу при условии, что
              администратор активировал HSM.
            </p>
          </div>
          <div class="d-6 d-offset-1 m-hide" v-onscroll>
            <img src="@/assets/pages/verification-center/pic-04.svg"
              data-copyright-source="https://storyset.com/illustration/secure-login/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container db13 mb10">
        <div class="row">
          <div class="d-12 db7 mb6" v-onscroll>
            <h2>Почему WorkCat</h2>
          </div>
          <div class="d-5" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/rocket.svg" />
              <h4 class="dt4 mt3 b2">Готовые решения</h4>
              <div class="sign-descr">
                Вам ничего не&nbsp;нужно разрабатывать с&nbsp;нуля.
                Мы&nbsp;соберём для вас персональное решение для КЭДО
                на&nbsp;базе технологий и&nbsp;продуктов Ediweb.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/sheld.svg" />
              <h4 class="dt4 mt3 b2">Защита от&nbsp;рисков</h4>
              <div class="sign-descr">
                Используем защищённые каналы связи и&nbsp;дата-центры
                с&nbsp;резервными мощностями. Документы храним в&nbsp;надёжном
                электронном архиве с&nbsp;фильтрами для быстрой выгрузки нужных
                файлов.
              </div>
            </div>
          </div>

          <div class="d-5" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/trust.svg" />
              <h4 class="dt4 mt3 b2">Опыт в&nbsp;ЭДО</h4>
              <div class="sign-descr">
                Разработчик WorkCat&nbsp;&mdash; доверенный оператор ФНС ЭДО
                <a href="https://ediweb.com/" target="_blank"
                >Ediweb</a>. Работем больше 20&nbsp;лет на&nbsp;рынке электронного
                документооборота.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/customize.svg" />
              <h4 class="dt4 mt3 b2">Кастомизация</h4>
              <div class="sign-descr">
                Доработаем функциональность продукта под внутренние процессы,
                адаптируем&nbsp;UI под ваш корпоративный стиль.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-light">
      <div class="container" v-onscroll>
        <div class="row db7 mb6">
          <div class="d-offset-1 d-9 dt12 mt7 d-center">
            <h2>Получите персональную консультацию</h2>
            <p class="lg">
              Оставьте заявку, и&nbsp;мы&nbsp;расскажем подробнее
              о&nbsp;функционале, вариантах внедрения и&nbsp;кастомизации,
              проведём презентацию решения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="d-offset-3 d-6 db12 mb7">
            <v-contact-form action="https://ediweb.com/subscribe/9040b663" :centered="true" :openForm="true" />
          </div>
        </div>
      </div>
    </div>

    <v-modal-wrapper name="contacts">
      <v-contact-form
        action="https://ediweb.com/subscribe/9040b663"
        title="Отправить запрос"
        description="Мы свяжемся с вами, проведём предварительную оценку
        и отправим вам индвидуальное предложение."
      >
      </v-contact-form>
    </v-modal-wrapper>
  </div>
</template>

<script>
const meta = {
  title: "WorkCat − автоматизация кадрового документооборота и HR-процессов",
  description: "Управление подписанием и маршрутизацией кадровых документов. Помощь в запуске проектов по КЭДО",
};

export default {
  name: "VerificationCenter",
  components: {
    "v-header": () => import("@/components/v-header.vue"),
    "v-modal-wrapper": () => import("@/components/v-modal-wrapper.vue"),
    "v-contact-form": () => import("@/components/v-contact-form.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  methods: {
    showModal() {
      this.$modal.show("contacts");
    },
  },
};
</script>
